import { Link } from '@remix-run/react';
import { Button, type ButtonProps } from '~/components/button';
import { env } from '~/config/env';
import { ROUTES } from '~/utils/routes';
import { cn } from '~/utils/cn';
import { useTracking } from 'react-tracking';

export const LANDING_CTA_LINK = ROUTES.SOLVE.FREE({
  id: env.VITE_APP === 'usmle' ? 1011 : 24132129,
});

export function LandingCTAButton(props?: ButtonProps) {
  const { trackEvent } = useTracking();

  return (
    <Link
      to={LANDING_CTA_LINK}
      className="block w-full md:inline-block md:w-auto"
    >
      <Button
        variant="theme"
        size="lg"
        {...props}
        className={cn(props?.className, 'w-full md:w-auto')}
        onClick={(event) => {
          trackEvent({
            event: 'try_button_click',
          });
          props?.onClick?.(event);
        }}
      >
        Try for free!
      </Button>
    </Link>
  );
}
